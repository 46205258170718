













































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MemberLoginHeader from './MemberLoginHeader.vue'
import MessageRequest from '@/layouts/MessageRequest.vue'
import CategoryService from '@/services/CategoryService'
import LogoService from '@/services/LogoService'
import { namespace } from 'vuex-class'
import OrderService from "@/services/OrderService";
const Auth = namespace('Auth')
const CartProductStore = namespace('CartProductStore')
const CartProductStoreEdit = namespace('CartProductStoreEdit')
const Category = namespace('Category')
const Notification = namespace('Notification')
const CategorySidebar = namespace('CategorySidebar')
const ModeCartMerge = namespace('ModeCartMerge')
const ModeCartEdit = namespace('ModeCartEdit')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component({
  components: {
    MemberLoginHeader,
    MessageRequest
  }
})
export default class Home extends Vue {
  @Auth.Getter
  public isLoggedIn!: boolean
  @Auth.State('user')
  public currentUser!: any
  public selectedStore: any = false
  public image: any = ''
  private categories = ''
  public showCategoryOnly: boolean = false
  public existOrderMergeAble: boolean = false
  private isCartIconDisplay: boolean = false

  @Auth.Action
  private signOut!: () => void
  @CartProductStore.Action
  private CLEAR_CART_PRODUCT!: () => void
  @CartProductStoreEdit.Getter
  private getCartEdit!: any
  @CartProductStoreEdit.Action
  private CLEAR_CART_PRODUCT_EDIT!: () => void
  @Category.Action
  private SET_CATEGORY!: (data: any) => any
  @Category.Action
  private CLEAR_CATEGORY!: () => void
  @Notification.Action
  private CLEAR_NOTIFICATION!: () => void

  @CategorySidebar.Getter
  private getModeViewCategory!: boolean
  @CategorySidebar.Action
  private DISABLE_VIEW_CATEGORY_ONLY!: (data: any) => any

  @ModeCartMerge.Action
  private SET_NEW_MODE_CART_MERGE!: (data: any) => any
  @ModeCartEdit.Action
  private SET_NEW_MODE_CART_EDIT!: (data: any) => any

  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number

  @Watch('getModeViewCategory')
  watchModeViewCategoryOnly() {
    this.handleShowCategoryOnly()
  }

  async created() {
    this.handleShowCategoryOnly()

    if (
      JSON.parse(JSON.stringify(localStorage.getItem('token'))) === 'undefined'
    ) {
      this.goToLogin()
    } else {
      this.selectedStore = localStorage.getItem('store_uuid') ? true : false
      await LogoService.getAvatar().then((res: any) => {
        this.image = res.data.items
      })

      // if response return have order before, go screen select order to merge
      if (this.getCartEdit.length === 0) {
        await this.checkExistOrderMergeAble()
      } else {
        this.isCartIconDisplay = true
      }

      if (localStorage.getItem('store_uuid')) {
        CategoryService.getCategory().then(
          (response: { data: { items: string } }) => {
            this.categories = response.data.items
            this.SET_CATEGORY(this.categories)
          },
          (error: {
            response: { data: any; status: any }
            message: any
            toString: () => any
          }) => {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
            this.categories =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          }
        )
      }
    }
  }

  public handleShowCategoryOnly() {
    if (this.getModeViewCategory === true) {
      this.showCategoryOnly = true
    } else {
      this.showCategoryOnly = false
    }
  }

  public disableViewCategoryOnly() {
    this.DISABLE_VIEW_CATEGORY_ONLY(false)
  }

  public goToLogin() {
    localStorage.clear()
    this.$router.push({ name: 'login' })
  }

  public logout() {
    this.CLEAR_CATEGORY()
    this.CLEAR_NOTIFICATION()
    this.CLEAR_CART_PRODUCT()
    this.CLEAR_CART_PRODUCT_EDIT()
    this.signOut()
    this.$router.push({ name: 'login' })
  }

  public goToCart() {
    if (localStorage.getItem('order_id_edit')) {
      let order_id = localStorage.getItem('order_id_edit')
          ? JSON.parse(JSON.stringify(localStorage.getItem('order_id_edit')))
          : ''
      this.SET_NEW_MODE_CART_EDIT(false)
      this.$router.push({
        name: 'order-edit',
        params: {
          order_id: order_id
        }
      })
    } else {
      if (localStorage.getItem('order_id_merge')) {
        let order_id = localStorage.getItem('order_id_merge')
            ? JSON.parse(JSON.stringify(localStorage.getItem('order_id_merge')))
            : ''
        this.SET_NEW_MODE_CART_MERGE(false)
        this.$router.push({
          name: 'order-merge',
          params: {
            order_id: order_id
          }
        })
      } else {
        if (this.getModeBuyProduct === 1) {
          let cart_product = JSON.parse(
              localStorage.getItem('cart_product') || ''
          )
          if (this.existOrderMergeAble === true && cart_product.length > 0) {
            this.$router.push({ name: 'order-merge-select' }).catch(() => {})
          } else {
            this.$router.push({ name: 'cart' }).catch(() => {})
          }
        }
      }
    }
    // this.$router.push({ name: 'cart' }).catch(() => {})
  }

  public goToHome() {
    this.$router.push({ name: 'home' }).catch(() => {})
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  public handleClickRouterNavSideBar(data: any) {
    if (this.$route.name === data) {
      let buttonCloseSidebar: HTMLElement = document.getElementById(
        'close-sidebar'
      ) as HTMLElement
      buttonCloseSidebar.click()
    } else {
      this.$router.push({ name: data }).catch(() => {})
    }
  }

  public async checkExistOrderMergeAble() {
    let tour_mode = localStorage.getItem('tour_mode') ? true : false
    if (!tour_mode) {
      await OrderService.getOrderMergeAble()
          .then((res: any) => {
            if (res.data.items.length > 0) {
              this.existOrderMergeAble = true
            }

            this.isCartIconDisplay = true
          })
          .catch((err: any) => {
            if (err.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
          })
    }
  }
}
